import styled from 'styled-components';

export interface IProps {
  isMain?: boolean;
  isLastItem?: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const IntroHolder = styled.section`
  overflow: hidden;
  width: 100vw;
`;

export const WallpaperHolder = styled.div`
  img,
  video {
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

export const ItemsHolder = styled.div`
  position: relative;
  top: -140px;

  @media (max-width: 813px) {
    top: -30px;
  }
`;
