import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const InputText = styled.textarea`
  text-transform: uppercase;
  display: block;
  width: 100%;
  padding: 10px;
  border: 2px solid ${colors.grey};
  background-color: transparent;
  box-sizing: border-box;
`;
