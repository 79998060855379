import styled from 'styled-components';

export const BrandHolder = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 150px;

  @media (max-width: 813px) {
    margin-bottom: 30px;
  }
`;

export const Brand = styled('div')`
  display: inline-block;
  width: 15%;

  img {
    width: 100%;
  }
`;

export const SectionTitleHolder = styled.div`
  margin: 120px 0 60px;

  @media (max-width: 813px) {
    margin: 30px 0 30px;
  }
`;
