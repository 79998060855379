import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Row, Col } from 'antd';
import {
  EcosystemStyled,
  Title,
  PortfolioItem,
  PortfolioTitles,
  PortfolioSection
} from './Ecosystem.style';
import SectionContent from '../../Elements/SectionContent';

export interface Query {
  allContentfulEcosystem: {
    nodes: {
      title: string;
      subTitle: string;
      order: number;
      image: {
        file: {
          contentType: string;
          fileName: string;
          url: string;
        };
        fluid: {
          src: string;
        };
      };
    }[];
  };
}

const isOdd = (n: number) => Math.abs(n % 2) === 1;

const query = graphql`
  query {
    allContentfulEcosystem {
      nodes {
        title
        subTitle
        order
        image {
          file {
            contentType
            fileName
            url
          }
          fluid(toFormat: JPG, quality: 100, maxWidth: 1200) {
            src
          }
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulEcosystem: { nodes }
  }: Query = useStaticQuery(query);
  const serviceItems = nodes
    .sort((a, b) => a.order - b.order)
    .map(item => (
      <Row key={item.title}>
        <PortfolioItem>
          <PortfolioSection
            style={{
              zIndex: 1,
              float: isOdd(item.order) ? 'left' : 'right',
              position: 'relative'
            }}
          >
            <PortfolioTitles alignment={isOdd(item.order) ? 'left' : 'right'}>
              <h1>{item.title}</h1>
              <h2>{item.subTitle}</h2>
            </PortfolioTitles>
          </PortfolioSection>
          <PortfolioSection
            style={{
              float: isOdd(item.order) ? 'right' : 'left'
            }}
          >
            <img
              src={item.image.fluid.src}
              alt={item.image.file.fileName}
              style={{ zIndex: -1 }}
            />
          </PortfolioSection>
        </PortfolioItem>
      </Row>
    ));

  return (
    <EcosystemStyled>
      <SectionContent>
        <Title>The Digital Ecosystem</Title>
        {serviceItems}
      </SectionContent>
    </EcosystemStyled>
  );
};
