import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../../Elements/Title';
import Description from '../../Elements/Description';
import PictureGrid from '../../Elements/PictureGrid';
import SectionContent from '../../Elements/SectionContent';
import { InnovationHolder, ContenHolder } from './Innovation.style';

export interface Query {
  allContentfulInnovation: {
    nodes: {
      title: string;
      description: {
        content: {
          content: {
            value: string;
          }[];
        }[];
      };
      background: {
        file: {
          url: string;
        };
        fluid: {
          src: string;
        };
      };
    }[];
  };
}

const query = graphql`
  query {
    allContentfulInnovation {
      nodes {
        title
        description {
          content {
            content {
              value
            }
          }
        }
        background {
          file {
            url
          }
          fluid(toFormat: JPG, quality: 100, maxWidth: 2200) {
            src
          }
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulInnovation: { nodes }
  }: Query = useStaticQuery(query);
  const innovationItem = nodes.length && nodes[0];

  if (!innovationItem) {
    return <div />;
  }

  return (
    <InnovationHolder>
      <PictureGrid src={innovationItem.background.fluid.src} />
      <SectionContent>
        <ContenHolder>
          <Title>{innovationItem.title}</Title>
          <Description>
            {innovationItem.description.content.map(item =>
              item.content.map((contentItem, index) => (
                <Fragment key={index}>
                  <span>{contentItem.value}</span>
                  <br />
                  <br />
                </Fragment>
              ))
            )}
          </Description>
        </ContenHolder>
      </SectionContent>
    </InnovationHolder>
  );
};
