import React, { ReactElement } from 'react';
import { PictureGrid, LineVertical, LineHorizontal } from './PictureGrid.style';

export interface Props {
  src: string;
  height?: number;
}

export default (props: Props) => (
  <PictureGrid src={props.src} height={props.height}>
    <LineVertical />
    <LineHorizontal />
  </PictureGrid>
);
