import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const PictureGrid = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${({ height }: { height?: number }) => (height ? height : 100)}%;
  background-image: url(${({ src }: { src: string }) => src});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LineVertical = styled.div`
  position: absolute;
  width: 10px;
  height: 100%;
  left: 50%;
  top: 0;
  margin-left: -5px;
  background-color: ${colors.white};
`;

export const LineHorizontal = styled.div`
  position: absolute;
  width: 100%;
  height: 10px;
  left: 0;
  top: 50%;
  margin-top: -5px;
  background-color: ${colors.white};
`;
