import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'antd';
import { ResultStyled, ResultContent, Description } from './Result.style';
import SectionContent from '../../Elements/SectionContent';
import Title from '../../Elements/Title';

export interface Query {
  allContentfulResult: {
    nodes: {
      title: string;
      subTitle: string;
      content: {
        json: {
          data: {};
          content: {
            data: {};
            content: {
              data: {};
              marks: [];
              value: string;
              nodeType: 'text';
            }[];
            nodeType: 'paragraph';
          }[];
          nodeType: 'document';
        };
      };
    }[];
  };
}

const isOdd = (n: number) => Math.abs(n % 2) == 1;

const query = graphql`
  query {
    allContentfulResult {
      nodes {
        title
        subTitle
        content {
          json
        }
      }
    }
  }
`;

export default () => {
  const {
    allContentfulResult: { nodes }
  }: Query = useStaticQuery(query);
  const node = nodes.length && nodes[0];

  if (!node) {
    return <div>no data</div>;
  }

  return (
    <SectionContent>
      <ResultContent>
        <Title align="left">{node.title}</Title>
        <Row gutter={30}>
          <Col md={10}>
            <Description>{node.subTitle}</Description>
          </Col>
          <Col md={14}>
            {node.content.json.content.map(item =>
              item.content.map(contenItem => (
                <p key={contenItem.value}>{contenItem.value}</p>
              ))
            )}
          </Col>
        </Row>
      </ResultContent>
    </SectionContent>
  );
};
