import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Title from '../../Elements/Title';
import Input from '../../Elements/Input';
import InputText from '../../Elements/InputText';
import Button from '../../Elements/Button';
// import Select from '../../Elements/Select';
import Description from '../../Elements/Description';
import SectionContent from '../../Elements/SectionContent';
import {
  ContactHolder,
  ContactForm,
  // ContactOptions,
  ContactOptionSticky,
  ContactAddress
} from './Contact.style';

export interface IAwards {
  allContentfulContact: {
    edges: {
      node: {
        title: string;
        locationTitle: string;
        location: {
          lon: number;
          lat: number;
        };
        contactTitle: string;
        address: string;
        contactOptions: {
          title: string;
          value: string;
        }[];
      };
    }[];
  };
}

interface State {
  contactOption: string;
}

class Contact extends Component<IAwards, State> {
  constructor(props: IAwards) {
    super(props);

    this.state = {
      contactOption: ''
    };

    this.setContactOption = this.setContactOption.bind(this);
  }

  setContactOption(e: React.FormEvent<HTMLSelectElement>) {
    this.setState({ contactOption: e.currentTarget.value });
  }

  render() {
    const {
      allContentfulContact: { edges }
    } = this.props;
    const { contactOption } = this.state;
    const contactItem = edges.length && edges[0].node;
    const whatsapp = contactItem.contactOptions.find(
      item => item.title === 'WHATSAPP'
    );

    console.log({ contactItem, whatsapp });

    return (
      <SectionContent>
        <ContactHolder>
          <ContactForm
            name="contact"
            method="POST"
            data-netlify="true"
            action="/success"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <Title>{contactItem.title}</Title>
            <Input name="name" type="text" placeholder="name" required />
            <InputText
              name="message"
              type="text"
              placeholder="message"
              required
            />
            <Button type="submit">SEND</Button>
          </ContactForm>

          {/* <ContactOptions>
            <Title>{contactItem.contactTitle}</Title>
            <Select onChange={this.setContactOption} value={contactOption}>
              <option value="">CHOOSE HOW</option>
              {contactItem.contactOptions.map((contactOption) => (
                <option key={contactOption.title} value={contactOption.value}>
                  {contactOption.title}
                </option>
              ))}
            </Select>
            <a href={contactOption} target="_blank">
              <Button style={{ opacity: contactOption ? 1 : 0 }} type="submit">
                CONTACT NOW
              </Button>
            </a>
          </ContactOptions> */}
          <ContactAddress>
            <div>
              <Title>{contactItem.locationTitle}</Title>
              <Description>{contactItem.address}</Description>
            </div>
            <a
              href={`https://www.google.com/maps/dir//${contactItem.location.lat},${contactItem.location.lon}`}
              target="_blank"
            >
              <Button>GET DIRECTIONS</Button>
            </a>
          </ContactAddress>
        </ContactHolder>
        {whatsapp && (
          <ContactOptionSticky href={whatsapp.value} target="_blank" />
        )}
      </SectionContent>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulContact {
          edges {
            node {
              title
              locationTitle
              location {
                lon
                lat
              }
              address
              contactTitle
              contactOptions {
                title
                value
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Contact allContentfulContact={data.allContentfulContact} />
    )}
  />
);
