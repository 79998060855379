import React from 'react';
import { Description, DescriptionProps } from './Description.style';

export interface Props extends DescriptionProps {
  children: React.ReactNode;
}

export default ({ children, ...rest }: Props) => (
  <Description {...rest}>{children}</Description>
);
