import styled from 'styled-components';

export const InnovationHolder = styled.div`
  @media (min-width: 813px) {
    margin-top: -200px;
    z-index: -1;
    position: relative;
  }
`;

export const ContenHolder = styled.div`
  @media (min-width: 813px) {
    position: absolute;
    bottom: 0;
    margin-bottom: -40px;
    background-color: white;
  }
`;
