import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export const EcosystemStyled = styled.div`
  padding: 120px 0;
  overflow: hidden;
  background-color: ${colors.white};
`;
export const Title = styled.h1`
  font-weight: bold;
`;

export interface PortfolioProps {
  alignment: 'left' | 'right';
}

export const PortfolioItem = styled.div`
  margin-top: 120px;
  width: 100%;

  img {
    transition: transform 0.2s;
    width: 100%;
  }

  :hover {
    img {
      transform: scale(1.2);
    }
  }
`;
export const PortfolioTitles = styled.div`
  h1 {
    position: relative;
    text-align: ${({ alignment }: PortfolioProps) =>
      alignment === 'left' ? 'right' : 'left'};
    color: ${colors.yellow};
    right: ${({ alignment }: PortfolioProps) =>
      alignment === 'left' ? '-7vw' : '7vw'};
    font-size: 90px;
    margin: 20px 0 0 0;
  }

  h2 {
    text-align: ${({ alignment }: PortfolioProps) =>
      alignment === 'left' ? 'right' : 'left'};
    font-size: 70px;
    margin: -20px 0 0 0;
  }
`;

export const PortfolioSection = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }
`;
