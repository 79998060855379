import styled from 'styled-components';
// import colors from '../../../constants/style/colors';

export const ResultStyled = styled.div`
  position: relative;
  bottom: -30px;
`;

export const ResultContent = styled.div`
  padding: 60px;

  h3 {
    padding: 0;
  }
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

export const Description = styled.div`
  font-size: 22px;
`;
