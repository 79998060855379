import styled from 'styled-components';
import colors from '../../../constants/style/colors';

export interface DescriptionProps {
  align?: 'left' | 'right' | 'center';
}

export const Description = styled('h3')`
  font-family: AvertaRegular;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 18px;
  color: ${colors.grey};
  text-align: ${({ align }: DescriptionProps) => (align ? align : 'center')};
  padding: 0 10%;

  @media (max-width: 1024px) {
    font-size: 12px;
  }
`;
