import React from "react";
// @ts-ignore
import { Element } from "react-scroll";
import Layout from "../components/Layout";
import Intro from "../components/Content/Intro";
// import Strategy from '../components/Content/Strategy';
import Service from "../components/Content/Service";
import Ecosystem from "../components/Content/Ecosystem";
import Innovation from "../components/Content/Innovation";
import Awards from "../components/Content/Awards";
// import Industries from '../components/Content/Industries';
// import Meeting from '../components/Content/Meeting';
import Contact from "../components/Content/Contact";
import Result from "../components/Content/Result";

const IndexPage = () => (
  <Layout isLandingPage>
    <script
      defer
      data-domain="yugen.online"
      src="https://plausible.io/js/script.js"
    />
    <Element name="#INTRO">
      <Intro />
    </Element>
    <Element name="#SERVICE">
      <Service />
    </Element>
    <Element name="#ECOSYSTEM">
      <Ecosystem />
    </Element>
    <Element name="#RESULT">
      <Result />
    </Element>
    <Element name="#INNOVATION">
      <Innovation />
    </Element>
    <Element name="#AWARDS">
      <Awards />
    </Element>
    <Element name="#GET GOING">
      {/* <Meeting /> */}
      <Contact />
    </Element>
    {
      /* <Element name="#INSTAGRAM">
      <Instagram />
    </Element> */
    }
  </Layout>
);

export default IndexPage;
